import { openBlock as o, createElementBlock as r, renderSlot as t } from "vue";
import { _ as s } from "./chunks/_plugin-vue_export-helper.CHgC5LLL.js";
import './css/Prose.css';const c = {}, n = { class: "prose" };
function d(e, _) {
  return o(), r("div", n, [
    t(e.$slots, "default", {}, void 0, !0)
  ]);
}
const l = /* @__PURE__ */ s(c, [["render", d], ["__scopeId", "data-v-d1764861"]]);
export {
  l as default
};
